<template>
  <div class="about-container overflow-hidden w-screen">
    <div class="relative bg-[#eae3d9]">
      <div class="w-full max-w-rifyo mx-auto pt-14 md:pt-24">
        <div class="md:hidden">
          <img
            :src="about.images"
            alt="mobile-banner-about"
            width="100%"
            height="auto"
          />
        </div>
        <div class="max-xl:px-7">
          <div class="relative mb-10">
            <div class="w-full md:w-10/12 ml-auto">
              <div class="hidden md:block min-h-[300px]">
                <img
                  :src="about.images"
                  alt="dekstop-banner-about"
                  width="100%"
                  height="auto"
                />
              </div>
            </div>
            <div class="md:absolute bottom-0 left-0 w-1/2 md:w-4/12 -mt-8 md:mt-0">
              <h1 class="page-title__About">{{ about.title }}</h1>
            </div>
          </div>
          <div class="flex flex-wrap -mx-4 -mt-4 pb-20">
            <div class="w-full px-4 pt-4">
              <div class="about-text" v-html="about.description"> </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
  import Footer from '../components/footer';
  import Api from '../api';
  import axios from 'axios';
  import Loading from '../components/Loading.vue';

  export default {
    name: 'About',
     data: () => ({
      about: [],
      isLoading: false,
    }),

    async created() {
      await this.getContent();
    },

    methods: {
      async getContent() {
        this.isLoading = true;
        try {
          const { data } = await axios.get(`${Api.about}`);
          this.about = data.data[0];
          this.isLoading = false;
        } catch (error) {
          throw new Error(error);
        }
      },
    },

    components: {
      Footer,
      Loading,
    },
  };
</script>

<style scoped>
.page-title__About {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 82px;
  color: #000;
}

.about-text {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  column-count: 2;
  word-break: break-word;
}

@media (max-width:767px) {
  .page-title__About {
    font-size: 48px;
    line-height: 64px;
  }

  .about-text {
    column-count: 1;
  }
}
</style>
